import React, { Fragment } from "react";
import Sidebar from "../components/sidebar.component";
import Footer from "../components/footer.component";
import Navbar from "../components/navbar.component";
import TopBarProgress from "react-topbar-progress-indicator";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Integration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ghlApiKey: "",
      ghlLocations: [],
      hunterApiKey: "",
    };
  }

  async componentDidMount() {
    const ghlApiKey = localStorage.getItem("ghlApiKey") || "";
    const hunterApiKey = localStorage.getItem("hunterApiKey") || "";
    const ghlLocations = JSON.parse(localStorage.getItem("ghlLocations")) || [];

    await this.setStateAsync(this, {
      loading: false,
      ghlApiKey: ghlApiKey || "",
      ghlLocations: ghlLocations || [],
      hunterApiKey: hunterApiKey || "",
    });
  }

  setStateAsync = (component, newState) => {
    return new Promise((resolve) => {
      component.setState(newState, () => {
        resolve();
      });
    });
  };

  maskInput = (value) => {
    const visibleLength = 6;
    const maskedValue = value
      ?.slice(0, visibleLength)
      .padEnd(value.length, "*");
    return maskedValue;
  };

  loadingHandler = async (value) => {
    await this.setStateAsync(this, {
      loading: value,
    });
  };

  connectGHL = async () => {
    try {
      await this.loadingHandler(true);

      const endpoint = "https://rest.gohighlevel.com/v1/locations/";
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${this.state.ghlApiKey}`,
        },
      });

      localStorage.setItem(
        "ghlLocations",
        JSON.stringify(response.data.locations || [])
      );

      await this.setStateAsync(this, {
        ghlLocations: response.data.locations,
      });
      await this.loadingHandler(false);
    } catch (error) {
      await this.loadingHandler(false);

      toast.error(error?.response?.data?.msg, {
        containerId: "B",
      });
    }
  };

  connectHunterIo = async () => {
    try {
      await this.loadingHandler(true);

      setTimeout(async () => {
        await this.loadingHandler(false);
      }, 1000);
    } catch (error) {
      await this.loadingHandler(false);
    }
  };

  render() {
    const { ghlLocations } = this.state;
    TopBarProgress.config({
      barColors: {
        0: "#CB3837",
        0.5: "#C836C3",
        "1.0": "#29ABE2",
      },
      shadowBlur: 5,
    });
    return (
      <Fragment>
        <div id="wrapper">
          {/* <Sidebar /> */}
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Navbar current="integrations" />

              <div className="container-fluid">
                {this.state.loading && <TopBarProgress />}
                <div
                  className="d-sm-flex align-items-center justify-content-between mb-4"
                  style={{ marginLeft: "2%" }}
                >
                </div>
                <div
                  className={`row ${this.state.loading ? "disabled" : ""}`}
                  style={{ marginLeft: "1%" }}
                >
                  <div className="col-lg-6 mb-4">
                    <div className="card shadow mb-4">
                      <div className="card-body">
                        <div className="form-group">
                          <label style={{ fontWeight: "bold" }}>
                          Go High Level API Key
                          </label>
                          <input
                            type="text"
                            placeholder="Please enter your Go High Level API Key..."
                            className="form-control"
                            value={this.maskInput(this.state.ghlApiKey)}
                            onChange={(e) => {
                              this.setState(
                                { ghlApiKey: e.target.value },
                                async () => {
                                  localStorage.setItem(
                                    "ghlApiKey",
                                    this.state.ghlApiKey
                                  );
                                }
                              );
                            }}
                          />
                        </div>

                        <div>
                          <button
                            className="btn btn-info btn-sm floatLeft"
                            style={{
                              background: "green",
                            }}
                            disabled={
                              this.state.ghlApiKey?.length > 0 ? false : true
                            }
                            onClick={this.connectGHL}
                          >
                            Connect
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <h2>Available Locations</h2>
                      <ul
                        className="list-group"
                        style={{
                          height: "70vh",
                          overFlow: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {ghlLocations?.length > 0 ? (
                          ghlLocations.map((item, index) => {
                            return (
                              <li className="list-group-item" key={index}>
                                <h5 className="mb-1">{item?.name}</h5>
                                <p className="mb-1">{item?.address}</p>
                              </li>
                            );
                          })
                        ) : (
                          <p>No locations found!</p>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-4">
                    <div className="card shadow mb-4">
                      <div className="card-body">
                        <div className="form-group">
                          <label style={{ fontWeight: "bold" }}>
                            Hunter.io API Key
                          </label>
                          <input
                            type="text"
                            placeholder="Please enter your Hunter.io API key..."
                            className="form-control"
                            value={this.maskInput(this.state.hunterApiKey)}
                            onChange={(e) => {
                              this.setState(
                                { hunterApiKey: e.target.value },
                                async () => {
                                  localStorage.setItem(
                                    "hunterApiKey",
                                    this.state.hunterApiKey
                                  );
                                }
                              );
                            }}
                          />
                        </div>

                        <div>
                          <button
                            className="btn btn-info btn-sm floatLeft"
                            style={{
                              background: "green",
                            }}
                            disabled={
                              this.state.hunterApiKey?.length > 0 ? false : true
                            }
                            onClick={this.connectHunterIo}
                          >
                            Connect
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Integration;
