import React from 'react';

const footer = () => {
  return (
    <>
    {/* <footer className="sticky-footer bg-white">
      <div className="container my-auto">
        <div className="copyright text-center my-auto">
          <span>Copyright ©{new Date().getFullYear()} v1.0.0</span>
        </div>
      </div>
    </footer> */}
    </>
  );
};

export default footer;
