import React, { Fragment } from "react";
import Sidebar from "../components/sidebar.component";
import Footer from "../components/footer.component";
import Navbar from "../components/navbar.component";
import TopBarProgress from "react-topbar-progress-indicator";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DOMAIN_SERVER } from "../../utils/states";

class Folders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      folder: "",
      color: "#0d6efd",
      folders: [],
    };
  }

  async componentDidMount() {
    await this.syncFolders();
    await this.loadingHandler(false);
  }

  setStateAsync = (component, newState) => {
    return new Promise((resolve) => {
      component.setState(newState, () => {
        resolve();
      });
    });
  };

  loadingHandler = async (value) => {
    await this.setStateAsync(this, {
      loading: value,
    });
  };

  syncFolders = async () => {
    var userId = localStorage.getItem("user_id");
    var folders = [];

    if (
      typeof userId === "undefined" ||
      userId === "" ||
      userId === "null" ||
      userId === null
    ) {
      localStorage.removeItem("email");
      window.location.href = "/";
    } else {
      var foldersRes = await axios.get(
        `${DOMAIN_SERVER}/api/get-folders/${userId}`
      );

      folders = foldersRes.data || [];
    }

    await this.setStateAsync(this, {
      folders,
    });

    return folders;
  };

  createFolder = async () => {
    try {
      await this.loadingHandler(true);
      var userId = localStorage.getItem("user_id");

      if (
        typeof userId === "undefined" ||
        userId === "" ||
        userId === "null" ||
        userId === null
      ) {
        localStorage.removeItem("email");
        window.location.href = "/";
      } else {
        await axios.post(`${DOMAIN_SERVER}/api/store-folder`, {
          title: this.state.folder,
          color: this.state.color,
          user_id: userId,
        });

        await this.syncFolders();

        await this.loadingHandler(false);

        await this.setStateAsync(this, { folder: "" });

        return true;
      }
    } catch (error) {
      await this.loadingHandler(false);
    }
  };

  deleteFolder = async (folder) => {
    try {
      // const conf = confirm("Are you sure you want to proceed?");

      // if (conf) {
      //   return false;
      // }
      await this.loadingHandler(true);
      var userId = localStorage.getItem("user_id");

      if (
        typeof userId === "undefined" ||
        userId === "" ||
        userId === "null" ||
        userId === null
      ) {
        localStorage.removeItem("email");
        window.location.href = "/";
      } else {
        await axios.post(`${DOMAIN_SERVER}/api/delete-folder`, {
          id: folder?.id,
          user_id: userId,
        });

        await this.syncFolders();

        await this.loadingHandler(false);

        return true;
      }
    } catch (error) {
      await this.loadingHandler(false);
    }
  };

  render() {
    const { folders } = this.state;
    TopBarProgress.config({
      barColors: {
        0: "#CB3837",
        0.5: "#C836C3",
        "1.0": "#29ABE2",
      },
      shadowBlur: 5,
    });
    return (
      <Fragment>
        <div id="wrapper">
          {/* <Sidebar /> */}
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Navbar current="folders" />

              <div className="container-fluid">
                {this.state.loading && <TopBarProgress />}
                <div
                  className="d-sm-flex align-items-center justify-content-between mb-4"
                  style={{ marginLeft: "2%" }}
                ></div>
                <div
                  className={`row ${this.state.loading ? "disabled" : ""}`}
                  style={{ marginLeft: "1%" }}
                >
                  <div className="col-lg-6 mb-4">
                    <div className="card shadow mb-4">
                      <div className="card-body">
                        <div className="form-group">
                          <label style={{ fontWeight: "bold" }}>
                            Create a new folder
                          </label>
                          <input
                            type="text"
                            placeholder="e.g. Warm Lead"
                            className="form-control"
                            value={this.state.folder}
                            onChange={(e) => {
                              this.setState({ folder: e.target.value });
                            }}
                          />
                        </div>

                        <div>
                          <button
                            className="btn btn-info btn-sm floatLeft"
                            style={{
                              background: "green",
                            }}
                            disabled={
                              this.state.folder?.length > 0 ? false : true
                            }
                            onClick={this.createFolder}
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    </div>

                    {folders && folders.length > 0 && (
                      <div className="card shadow mb-4">
                        <div className="card-body">
                          {folders &&
                            folders.map((item, index) => (
                              <button
                                key={index}
                                className="btn btn-labeled"
                                style={{
                                  backgroundColor: item?.color,
                                  color: "white",
                                  cursor: "default",
                                  border: "none",
                                  marginLeft: 20,
                                }}
                              >
                                <span
                                  className="btn-label"
                                  onClick={() => {
                                    this.deleteFolder(item);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    background: "grey",
                                  }}
                                >
                                  <i
                                    className="fas fa-trash"
                                    style={{
                                      fontSize: 13,
                                      color: "red",
                                    }}
                                  ></i>
                                </span>

                                {item?.title}
                              </button>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Folders;
