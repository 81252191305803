import React, { Fragment, useEffect } from "react";
import Sidebar from "../components/sidebar.component";
import Footer from "../components/footer.component";
import Navbar from "../components/navbar.component";
import TopBarProgress from "react-topbar-progress-indicator";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Support extends React.Component {
  componentDidMount() {
    // Dynamically load the Freshdesk widget script
    const script = document.createElement("script");
    script.src =
      "https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up by removing the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }

  render() {
    return (
      <Fragment>
        <div id="wrapper">
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Navbar current="support" />

              <div className="container-fluid">
                <div
                  className="d-sm-flex align-items-center justify-content-between mb-4"
                  style={{ marginLeft: "2%" }}
                ></div>
                <div className={`row`} style={{ marginLeft: "1%" }}>
                  <div className="col-lg-3"></div>
                  <div className="col-lg-6 mb-4">
                    <style
                      type="text/css"
                      media="screen, projection"
                      dangerouslySetInnerHTML={{
                        __html: `
                        @import url('https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css');
                      `,
                      }}
                    />
                    <iframe
                      title="Feedback Form"
                      className="freshwidget-embedded-form"
                      id="freshwidget-embedded-form"
                      src="https://leadgorilla.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Help+%26+Support&submitTitle=Submit&submitThanks=Thank+you+for+your+message&searchArea=no"
                      scrolling="no"
                      height="850px"
                      width="100%"
                      frameBorder="0"
                    ></iframe>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Support;
